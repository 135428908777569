import React, { ReactNode } from "react";
import styled from "styled-components";

const Align = React.memo(styled.div<{
  show: boolean;
  imageOffset: number;
}>`
  position: fixed;
  display: flex;
  transform: translateY(0px);
  top: 0px;
  align-items: center;
  height: 100vh;
  z-index: 3;

  opacity: ${p => (p.show ? 1 : 0)};
  visibility: ${p => (p.show ? "visible" : "hidden")};
  transition: ${p =>
    p.show
      ? "opacity 0.4s linear, visibility 0.4s linear"
      : "opacity 0.2s linear, visibility 0.4s linear"};
`);

const AsideContainer = styled.aside`
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: ${p => p.theme.dimensions.maxContentWidth};

  ${p => p.theme.media.desktop_medium`
    display: none;
  `}
`;

export default function FixedAside({ children }: { children: ReactNode }) {
  return (
    <AsideContainer>
      <Align show>{children}</Align>
    </AsideContainer>
  );
}
