import React from "react";
import { ScrollContextProvider } from "~/contexts/ScrollContext";
import FullpageInstance from "~/sections/FullpageWrapper";
import ScrollProgressIndicator from "~/components/ScrollProgressIndicator";
import SEO from "~/components/SEO";
import FixedAside from "~/sections/Home/FixedAside";

export default function IndexPage() {
  return (
    typeof window !== "undefined" && (
      <>
        <ScrollContextProvider>
          <SEO title="Home" />
          <FullpageInstance />
          <FixedAside>
            <ScrollProgressIndicator trackFullpage />
          </FixedAside>
        </ScrollContextProvider>
      </>
    )
  );
}
