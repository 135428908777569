import React, { useEffect } from "react";
import { FullPageItem } from "~/types/FullPage";
import { useScrollContext } from "~/contexts/ScrollContext";
import ReactFullpage, { FullpageApi } from "@fullpage/react-fullpage";
import sectionRoutes from "~/sections/Home";
import { SCROLLABLE_CONTENT_SEL, SECTION_SEL } from "~/globals";
import Section from "~/components/Section";

interface FullpageWrapperProps {
  state: any;
  fullpageApi: FullpageApi;
}

export function FullpageWrapper({ fullpageApi }: FullpageWrapperProps) {
  const { fullpageApi: globalFullpageApiRef, setIsFullpageLoaded } = useScrollContext();

  useEffect(() => {
    globalFullpageApiRef.current = fullpageApi;
    if (!!fullpageApi) {
      setIsFullpageLoaded(true);
    }
  }, [fullpageApi]);

  return (
    <ReactFullpage.Wrapper>
      {sectionRoutes.map(({ children, ...sectionRoute }) => (
        <Section key={sectionRoute.href} {...sectionRoute}>
          {children}
        </Section>
      ))}
    </ReactFullpage.Wrapper>
  );
}

export default function FullpageInstance() {
  const { setScrollIndex } = useScrollContext();

  const handleNewSection = (_: any, destination: FullPageItem) => {
    setScrollIndex(destination.index);
  };

  return (
    <ReactFullpage
      licenseKey={process.env.GATSBY_FULLPAGEJS_KEY}
      sectionSelector={SECTION_SEL}
      scrollOverflow
      anchors={sectionRoutes.map(({ href }) => href)}
      // menu
      onLeave={handleNewSection}
      afterLoad={handleNewSection}
      verticalCentered={false}
      // scrollHorizontally
      normalScrollElements={`.${SCROLLABLE_CONTENT_SEL}`}
      render={props => <FullpageWrapper {...props} />}
    />
  );
}
