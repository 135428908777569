import styled, { css } from "styled-components";
import { SECTION_SEL } from "~/globals";

interface SectionProps {
  verticalCentered?: boolean;
  overflows?: boolean;
}

const centeredMixin = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const startAlignedMixin = css<SectionProps>`
  ${({ overflows, theme }) => `
    display: block;
    box-sizing: border-box;
  `}
`;

const Section = styled.section.attrs({ className: SECTION_SEL })<SectionProps>`
  ${({ verticalCentered }) => (verticalCentered ? centeredMixin : startAlignedMixin)}
`;

Section.defaultProps = { verticalCentered: true, overflows: false };

export default Section;
